import { config } from '../../../config';

export interface DriverRequest {
    vin: string;
    driver_identification: {
        identification_type: string;
        identification: string;
    };
    username: {
        preferred_login: 'phone' | 'email';
        value: string;
    };
    communication_language: string;
}

export const submitDriverRequest = (driverRequest: DriverRequest) => {
    const url = config.backend.userSelfRegistration + '/driver-registrations';

    const postRequest = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(driverRequest),
    };
    return fetch(url, postRequest);
};
