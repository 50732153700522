import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { validate, ValidationType } from './utils/validators';
import { DriverCardCountry } from './utils/driverCardCountry';
import { FirstScreenInput } from './components/FirstScreenInput';
import { FirstScreenSelect } from './components/FirstScreenSelect';

interface Props {
    setVin: (vin: string) => void;
    setDriverCardNumber: (driverCardNumber: string) => void;
    vin: string;
    driverCardNumber: string;
    setStep: (step: number) => void;
    driverCardCountry: DriverCardCountry;
    setDriverCardCountry: (country: DriverCardCountry) => void;
}

const validateVin = (vin: string): boolean => validate({ type: ValidationType.Vin, value: vin });
const validateDriverCard = (driverCardNumber: string): boolean =>
    validate({ type: ValidationType.DriverCard, value: driverCardNumber });

export const FirstScreen = (props: Props) => {
    const { vin, driverCardNumber, setVin, setDriverCardNumber, setStep, driverCardCountry, setDriverCardCountry } =
        props;

    const [inputErrors, setInputErrors] = useState({ vin: false, driverCard: false });

    useEffect(() => {
        const isVinValid = validateVin(vin) || vin.length === 0;
        setInputErrors((i) => ({ ...i, vin: !isVinValid }));
    }, [vin]);

    useEffect(() => {
        const isDriverCardNumber = validateDriverCard(driverCardNumber) || driverCardNumber.length === 0;
        setInputErrors((i) => ({ ...i, driverCard: !isDriverCardNumber }));
    }, [driverCardNumber]);

    const handleVinChange = (event: FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.value.length <= 17) {
            // maxLength does not work on Android
            setVin(event.currentTarget.value.toUpperCase());
        }
    };
    const handleDriverCardNumberChange = (event: FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.value.length <= 16) {
            // maxLength does not work on Android
            setDriverCardNumber(event.currentTarget.value.toUpperCase());
        }
    };

    const handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setDriverCardCountry(event.target.value as DriverCardCountry);
    };

    const nextStep = () => {
        if (!inputErrors.vin && !inputErrors.driverCard) {
            setStep(2);
        }
    };

    const isNextButtonDisabled = () =>
        vin.length < 17 || driverCardNumber.length < 16 || inputErrors.vin || inputErrors.driverCard;

    return (
        <>
            <div className={'text-size-16'}>
                <span className={'rioglyph text-color-darker rioglyph-number-1 text-size-400pct'} />
            </div>
            <div className={'text-size-h4 text-medium text-center'}>
                <FormattedMessage id={'driverRegistration.page1.title'} />
            </div>
            <div
                className={
                    'flex-1-1 width-100pct margin-top-15pct display-flex flex-column justify-content-center align-items-center'
                }
            >
                <FirstScreenInput
                    inputErrors={inputErrors}
                    inputValue={vin}
                    inputType={'vin'}
                    onChange={handleVinChange}
                />

                <FirstScreenInput
                    inputErrors={inputErrors}
                    inputValue={driverCardNumber}
                    inputType={'driverCardNumber'}
                    onChange={handleDriverCardNumberChange}
                />

                <FirstScreenSelect driverCardCountry={driverCardCountry} onChange={handleDropdownChange} />
            </div>
            <div className={'btn-toolbar margin-top-10pct margin-bottom-20'}>
                <button
                    className={'btn btn-lg btn-primary'}
                    onClick={nextStep}
                    disabled={isNextButtonDisabled()}
                    data-cy={'page-1-next'}
                >
                    <FormattedMessage id={'driverRegistration.nextButtonText'} />
                </button>
            </div>
        </>
    );
};
