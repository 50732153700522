import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { DriverContainer } from '../driver/DriverContainer';

const App = () => {
    return (
        <ApplicationLayout className={'UserSelfRegistration'}>
            <ApplicationLayout.Body innerClassName={'padding-0'}>
                <Routes>
                    <Route path={'driver'} element={<DriverContainer />} />
                    <Route path={'*'} element={<Navigate to={'/driver'} />} />
                </Routes>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};

export default App;
