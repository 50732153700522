import { DEFAULT_LOCALE, getSupportedLocale } from './lang/lang';
import { importDisplayMessages } from './lang/services';

export const main = async (renderApp: Function) => {
    const userLocale = getSupportedLocale(navigator.language);
    const displayMessages = await importDisplayMessages(userLocale);

    // wait 300ms before rendering the app to make sure uikit css is loaded
    // setTimeout(() => {
    //     renderApp({ locale: userLocale, displayMessages: displayMessages });
    // }, 500);

    renderApp({ locale: userLocale, displayMessages: displayMessages });
};

export { DEFAULT_LOCALE };
