import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './features/app/App';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { NoMatch } from './features/app/NoMatch';
import { createRoot } from 'react-dom/client';
import { DEFAULT_LOCALE, main } from './configuration';
import { IntlProvider } from 'react-intl';

const renderApplication = ({ locale, displayMessages }: { locale: string; displayMessages: any }) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <BrowserRouter>
                <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={displayMessages}>
                    <Routes>
                        <Route path={'/*'} element={<App />} />
                        <Route path={'/error'} element={<NoMatch />} />
                        <Route path={'*'} element={<NoMatch />} />
                    </Routes>
                </IntlProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

main(renderApplication);
