import { FormattedMessage, useIntl } from 'react-intl';
import { extractLanguage } from '../../../configuration/lang/lang';
import { CharCount } from './CharCount';
import { FormEvent } from 'react';

type SupportedTypes = 'vin' | 'driverCardNumber';

interface Props {
    inputErrors: any;
    inputValue: string;
    inputType: SupportedTypes;
    onChange: (arg: FormEvent<HTMLInputElement>) => void;
}

export const FirstScreenInput = ({ inputErrors, inputValue, inputType, onChange }: Props) => {
    const intl = useIntl();
    const linkLanguage = extractLanguage(intl.locale) === 'de' ? 'de' : 'en';

    let inputError: boolean;
    let urlSuffix: string;
    let exactLength: number;

    switch (inputType) {
        case 'vin':
            inputError = inputErrors.vin;
            urlSuffix = 'vin';
            exactLength = 17;
            break;
        case 'driverCardNumber':
            inputError = inputErrors.driverCard;
            urlSuffix = 'driver-card';
            exactLength = 16;
            break;
    }

    return (
        <div
            className={
                'form-group width-100pct' +
                (inputError ? ' has-error has-feedback' : '') +
                (inputValue.length > 0 && inputValue.length < exactLength ? ' has-error' : '')
            }
        >
            <label className={'control-label display-flex justify-content-between align-items-end'}>
                <span className={'text-size-14 line-height-125rel'}>
                    <FormattedMessage id={`driverRegistration.page1.${inputType}.label`} />
                </span>
                <a
                    className={'link white-space-nowrap margin-left-10'}
                    href={`https://rio.cloud/${linkLanguage}/glossar/${urlSuffix}?noCookie=1`}
                    target={'_blank'}
                    rel={'noreferrer'}
                    tabIndex={-1}
                >
                    <FormattedMessage id={'driverRegistration.page1.helpHint'} />
                </a>
            </label>
            <div className={'position-relative'}>
                <input
                    type={'text'}
                    className={'form-control input-lg'}
                    placeholder={intl.formatMessage({ id: `driverRegistration.page1.${inputType}.placeholder` })}
                    onChange={onChange}
                    value={inputValue}
                    data-cy={`${urlSuffix}-input`}
                    maxLength={exactLength}
                    spellCheck={false}
                />
                {inputError && inputValue.length === exactLength && (
                    <span className={'form-control-feedback rioglyph rioglyph-error-sign'} />
                )}
                <CharCount inputType={inputType} value={inputValue} />
            </div>
            {inputError && (
                <span className={'help-block'}>
                    <FormattedMessage id={`driverRegistration.page1.${inputType}.validationError`} />
                </span>
            )}
        </div>
    );
};
