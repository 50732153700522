import { FormEvent, useEffect, useState } from 'react';
import { PreferredLogin } from './types';
import { validate, ValidationType } from './utils/validators';
import { FormattedMessage } from 'react-intl';
import { SecondScreenInput } from './components/SecondScreenInput';
import { getPhonePrefixFromDriverCardCountry } from './utils/phonePrefix';
import { DriverCardCountry } from './utils/driverCardCountry';

interface Props {
    email: string;
    phone: string;
    preferredLogin: PreferredLogin;
    setEmail: (input: string) => void;
    setPhone: (input: string) => void;
    setPreferredLogin: (input: PreferredLogin) => void;
    setStep: (input: number) => void;
    submit: () => Promise<number>;
    driverCardCountry: DriverCardCountry;
    pending: boolean;
}

const validateEmail = (email: string): boolean => validate({ type: ValidationType.Email, value: email });

const validatePhone = (phone: string): boolean => validate({ type: ValidationType.Phone, value: phone });

export const SecondScreen = (props: Props) => {
    const {
        email,
        phone,
        preferredLogin,
        setEmail,
        setPhone,
        setPreferredLogin,
        setStep,
        submit,
        pending,
        driverCardCountry,
    } = props;

    const [errorCode, setErrorCode] = useState<undefined | number>();
    const [inputErrors, setInputErrors] = useState({ email: false, phone: false });

    const [submitted, setSubmitted] = useState(false);

    const phonePrefix = getPhonePrefixFromDriverCardCountry(driverCardCountry);

    const handleSetEmail = (event: FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };
    const handleSetPhone = (event: FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.value.length <= 16) {
            // maxLength does not work on Android
            setPhone(event.currentTarget.value);
        }
    };

    const handleSelectEmail = () => {
        setInputErrors({ ...inputErrors, phone: false });
        setPreferredLogin('email');
        setPhone('');

        try {
            setFocus(document.getElementById('email-input') as HTMLInputElement);
        } catch (e) {
            console.error('No input element for phone number found');
        }
    };

    const handleSelectPhone = () => {
        setInputErrors({ ...inputErrors, email: false });
        setPreferredLogin('phone');
        setEmail('');
        if (phone === '') {
            setPhone(phonePrefix);
        }

        try {
            setFocus(document.getElementById('phone-input') as HTMLInputElement);
        } catch (e) {
            console.error('No input element for phone number found');
        }
    };

    useEffect(() => {
        try {
            setFocus(document.getElementById('email-input') as HTMLInputElement, 250);
        } catch (e) {
            console.error('No input element for phone number found');
        }
    }, []);

    useEffect(() => {
        if (submitted) {
            const isEmailValid = validateEmail(email) || email.length === 0;
            setInputErrors((i) => ({ ...i, email: !isEmailValid }));
        }
    }, [email, submitted]);

    useEffect(() => {
        if (submitted) {
            const isPhoneValid = validatePhone(phone) || phone.length === 0;
            setInputErrors((i) => ({ ...i, phone: !isPhoneValid }));
        }
    }, [phone, submitted]);

    const handleConfirm = async () => {
        const isValidEmail = validateEmail(email);
        const isValidPhone = validatePhone(phone);
        if ((preferredLogin === 'email' && isValidEmail) || (preferredLogin === 'phone' && isValidPhone)) {
            setInputErrors({ email: false, phone: false });
            setSubmitted(true);

            await submit().then((res) => {
                if (res !== 201) {
                    setErrorCode(res);
                } else {
                    setErrorCode(undefined);
                    setStep(3);
                }
            });
        } else {
            setInputErrors({
                email: preferredLogin === 'email' && !isValidEmail,
                phone: preferredLogin === 'phone' && !isValidPhone,
            });
            setSubmitted(true);
        }
    };

    const handleBack = () => {
        setStep(1);
    };

    const isButtonDisabled = () =>
        (!email && !phone) ||
        pending ||
        inputErrors.email ||
        inputErrors.phone ||
        (preferredLogin === 'email' && email.length < 5) ||
        (preferredLogin === 'phone' && phone.length < 7);

    return (
        <>
            <div className={'text-size-16'}>
                <span className={'rioglyph text-color-darker rioglyph-number-2 text-size-400pct'} />
            </div>
            <div className={'text-size-h4 text-medium text-center'}>
                <FormattedMessage id={'driverRegistration.page2.title'} />
            </div>
            <div
                className={
                    'flex-1-1 width-100pct margin-top-15pct display-flex flex-column justify-content-center align-items-center'
                }
            >
                <SecondScreenInput
                    value={email}
                    inputType={'email'}
                    inputErrors={inputErrors}
                    preferredLogin={preferredLogin}
                    handleRadioSelection={handleSelectEmail}
                    handleInput={handleSetEmail}
                    isPending={pending}
                />

                <SecondScreenInput
                    value={phone}
                    inputType={'phone'}
                    inputErrors={inputErrors}
                    preferredLogin={preferredLogin}
                    handleRadioSelection={handleSelectPhone}
                    handleInput={handleSetPhone}
                    maxLength={16}
                    isPending={pending}
                    driverCardCountry={driverCardCountry}
                />
            </div>
            <div>
                {pending ? (
                    <div className={'text-color-info text-center'}>
                        <FormattedMessage id={'driverRegistration.page2.pendingRequest'} />
                    </div>
                ) : errorCode ? (
                    <div className={'text-color-danger text-center'} data-cy={'page-2-error'}>
                        <FormattedMessage id={`driverRegistration.page2.error.${errorCode}`} />
                    </div>
                ) : (
                    <div>&nbsp;</div>
                )}
            </div>
            <div className={'btn-toolbar margin-top-10pct margin-bottom-20'}>
                <button className={'btn btn-lg btn-default btn-outline margin-right-15'} onClick={handleBack}>
                    <FormattedMessage id={'driverRegistration.backButtonText'} />
                </button>
                <button
                    className={'btn btn-lg btn-primary' + (pending ? ' btn-loading' : '')}
                    onClick={handleConfirm}
                    disabled={isButtonDisabled()}
                    data-cy={'page-2-next'}
                >
                    <FormattedMessage id={'driverRegistration.confirmButtonText'} />
                </button>
            </div>
        </>
    );
};

const setFocus = (targetInput: HTMLInputElement, timeOut: number = 100) => {
    // Fix focus for iOS: https://stackoverflow.com/a/55652503
    // create invisible dummy input to receive the focus first
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.readOnly = true;
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = '0';
    fakeInput.style.height = '0';
    fakeInput.style.fontSize = '16px'; // disable auto zoom

    // you may need to append to another element depending on the browser's auto
    // zoom/scroll behavior
    document.body.prepend(fakeInput);

    // focus so that subsequent async focus will work
    fakeInput.focus();

    setTimeout(() => {
        // now we can focus on the target input
        targetInput.focus();

        // cleanup
        fakeInput.remove();
    }, timeOut);
};
