import { DriverCardCountry } from './driverCardCountry';

export const getPhonePrefixFromLocale = (locale: string): string => {
    switch (locale) {
        case 'en-GB':
            return '+44';
        case 'bg-BG':
            return '+359';
        case 'cs-CZ':
            return '+420';
        case 'da-DK':
            return '+45';
        case 'de-DE':
            return '+49';
        case 'el-GR':
            return '+30';
        case 'es-ES':
            return '+34';
        case 'et-EE':
            return '+372';
        case 'fi-FI':
            return '+358';
        case 'fr-FR':
            return '+33';
        case 'hr-HR':
            return '+358';
        case 'hu-HU':
            return '+36';
        case 'it-IT':
            return '+39';
        case 'lt-LT':
            return '+370';
        case 'lv-LV':
            return '+371';
        case 'nb-NO':
            return '+47';
        case 'nl-NL':
            return '+31';
        case 'pl-PL':
            return '+48';
        case 'pt-PT':
            return '+351';
        case 'ro-RO':
            return '+40';
        case 'sk-SK':
            return '+421';
        case 'sl-SI':
            return '+386';
        case 'sv-SE':
            return '+46';
        default:
            return '';
    }
};

export const getPhonePrefixFromDriverCardCountry = (country: DriverCardCountry): string => {
    switch (country) {
        case 'AUSTRIA':
            return '+43';
        case 'ALBANIA':
            return '+355';
        case 'ANDORRA':
            return '+376';
        case 'ARMENIA':
            return '+374';
        case 'AZERBAIJAN':
            return '+994';
        case 'BELGIUM':
            return '+32';
        case 'BULGARIA':
            return '+359';
        case 'BOSNIA_HERZEGOVINA':
            return '+387';
        case 'BELARUS':
            return '+375';
        case 'SWITZERLAND':
            return '+41';
        case 'CYPRUS':
            return '+357';
        case 'CZECH_REPUBLIC':
            return '+420';
        case 'GERMANY':
            return '+49';
        case 'DENMARK':
            return '+45';
        case 'SPAIN':
            return '+34';
        case 'ESTONIA':
            return '+372';
        case 'FRANCE':
            return '+33';
        case 'FINLAND':
            return '+358';
        case 'LIECHTENSTEIN':
            return '+423';
        case 'FAROE_ISLANDS':
            return '+298';
        case 'UNITED_KINGDOM':
            return '+44';
        case 'GEORGIA':
            return '+995';
        case 'GREECE':
            return '+30';
        case 'HUNGARY':
            return '+36';
        case 'CROATIA':
            return '+385';
        case 'ITALY':
            return '+39';
        case 'IRELAND':
            return '+353';
        case 'ICELAND':
            return '+354';
        case 'KAZAKHSTAN':
            return '+7';
        case 'LUXEMBOURG':
            return '+352';
        case 'LITHUANIA':
            return '+370';
        case 'LATVIA':
            return '+371';
        case 'MALTA':
            return '+356';
        case 'MONACO':
            return '+377';
        case 'REPUBLIC_OF_MOLDOVA':
            return '+373';
        case 'MACEDONIA':
            return '+389';
        case 'NORWAY':
            return '+47';
        case 'NETHERLANDS':
            return '+31';
        case 'PORTUGAL':
            return '+351';
        case 'POLAND':
            return '+48';
        case 'ROMANIA':
            return '+40';
        case 'SAN_MARINO':
            return '+378';
        case 'RUSSIAN_FEDERATION':
            return '+7';
        case 'SWEDEN':
            return '+46';
        case 'SLOVAKIA':
            return '+421';
        case 'SLOVENIA':
            return '+386';
        case 'TURKMENISTAN':
            return '+993';
        case 'TURKEY':
            return '+90';
        case 'UKRAINE':
            return '+380';
        case 'VATICAN_CITY':
            return '+379';
        default:
            return '+';
    }
};
