import { FormattedMessage } from 'react-intl';
import { PreferredLogin } from './types';

export const ThirdScreen = ({ preferredLogin }: { preferredLogin: PreferredLogin }) => {
    return (
        <>
            <div className={'text-size-h4 text-medium text-center margin-top-15pct'}>
                <FormattedMessage id={'driverRegistration.page3.title'} />
            </div>
            <div
                className={
                    'flex-1-1 width-100pct margin-top-10pct display-flex flex-column justify-content-center align-items-center'
                }
            >
                <div
                    className={'text-size-16 text-center margin-bottom-15pct max-width-300'}
                    data-cy={'page-3-success'}
                >
                    <FormattedMessage id={'driverRegistration.page3.successMessage'} />
                </div>
                <div className={'text-size-16 alert alert-info text-center'}>
                    <FormattedMessage
                        id={'driverRegistration.page3.confirmationHint'}
                        values={{ notificationMethod: preferredLogin === 'phone' ? 'SMS' : 'Email' }}
                    />
                </div>
            </div>
            <div className={'margin-bottom-25'} />
        </>
    );
};
