import packageJson from '../package.json';

export interface ConfigState {
    homeRoute: string | undefined;
    backend: {
        userSelfRegistration: string;
    };
    id: string | undefined;
    serviceVersion: string;
    serviceEnvironment: string;
    sentryToken: string | undefined;
}

export const config: ConfigState = {
    homeRoute: import.meta.env.VITE_APP_HOME_ROUTE,
    backend: {
        userSelfRegistration: import.meta.env.VITE_USER_SELF_REGISTRATION,
    },
    id: import.meta.env.VITE_APP_ID,
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    sentryToken: import.meta.env.VITE_APP_SENTRY_DSN,
};
