import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FirstScreen } from './FirstScreen';
import { SecondScreen } from './SecondScreen';
import { ThirdScreen } from './ThirdScreen';
import { DriverRequest, submitDriverRequest } from './utils/requests';
import { getCountryFromLocale, mapDriverCardCountryToPrefix } from './utils/driverCardCountry';
import { PreferredLogin } from './types';

export const DriverContainer = () => {
    const locale = useIntl().locale;

    const [step, setStep] = useState(1);

    const [vin, setVin] = useState('');
    const [driverCardNumber, setDriverCardNumber] = useState('');

    const [driverCardCountry, setDriverCardCountry] = useState(getCountryFromLocale(locale));

    const [preferredLogin, setPreferredLogin] = useState<PreferredLogin>('email');

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [pending, setPending] = useState(false);

    const submit = async (): Promise<number> => {
        setPending(true);

        const prefix = mapDriverCardCountryToPrefix(driverCardCountry);

        const driverRequest: DriverRequest = {
            vin: vin,
            driver_identification: {
                identification_type: 'eu-tachograph-card', // This is currently hard coded, as we only accept eu-tachograph cards
                identification: prefix + driverCardNumber,
            },
            username: email
                ? {
                      preferred_login: 'email',
                      value: email.trim(),
                  }
                : {
                      preferred_login: 'phone',
                      value: phone.trim(),
                  },
            communication_language: locale,
        };
        return await submitDriverRequest(driverRequest).then((res) => {
            setPending(false);
            return res.status;
        });
    };

    const renderScreen = () => {
        switch (step) {
            case 2:
                return (
                    <SecondScreen
                        email={email}
                        phone={phone}
                        preferredLogin={preferredLogin}
                        setEmail={setEmail}
                        setPhone={setPhone}
                        setPreferredLogin={setPreferredLogin}
                        setStep={setStep}
                        submit={submit}
                        pending={pending}
                        driverCardCountry={driverCardCountry}
                    />
                );
            case 3:
                return <ThirdScreen preferredLogin={preferredLogin} />;
            default:
                return (
                    <FirstScreen
                        vin={vin}
                        driverCardNumber={driverCardNumber}
                        setVin={setVin}
                        setDriverCardNumber={setDriverCardNumber}
                        setStep={setStep}
                        driverCardCountry={driverCardCountry}
                        setDriverCardCountry={setDriverCardCountry}
                    />
                );
        }
    };

    return (
        <div className={'display-flex justify-content-center min-height-100vh padding-25'}>
            <div className={'display-flex flex-column margin-10-sm width-100pct max-width-400 align-self-center'}>
                <div
                    className={'panel-default panel panel-body padding-25 display-flex flex-column align-items-center'}
                >
                    {renderScreen()}
                </div>
                <footer className="display-flex justify-content-end">
                    <img src="https://cdn.rio.cloud/svg/common/ico_rio_powered.svg" alt={'RIO logo'} />
                </footer>
            </div>
        </div>
    );
};
