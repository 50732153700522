export const CharCount = ({ inputType, value }: { inputType: 'vin' | 'driverCardNumber'; value: string }) => {
    let currentCharacters = value.length;
    let requiredCharacters: number;
    switch (inputType) {
        case 'vin':
            requiredCharacters = 17;
            break;
        case 'driverCardNumber':
            requiredCharacters = 16;
            break;
    }

    const renderDiv = currentCharacters !== requiredCharacters && currentCharacters > 0;
    return renderDiv ? (
        <div
            className={
                'text-color-danger position-absolute right-10 top-50pct transform translate-y-50pct text-italic z-index-3 pointer-events-none'
            }
        >
            {`${currentCharacters} / ${requiredCharacters}`}
        </div>
    ) : (
        <></>
    );
};
