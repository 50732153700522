import { FormattedMessage, useIntl } from 'react-intl';
import { driverCardCountries, DriverCardCountry, driverCardCountryGenericValues } from '../utils/driverCardCountry';
import { ChangeEvent } from 'react';

export const FirstScreenSelect = ({
    onChange,
    driverCardCountry,
}: {
    driverCardCountry: DriverCardCountry;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}) => {
    const intl = useIntl();

    return (
        <div className={'form-group width-100pct'}>
            <label className={'control-label display-flex justify-content-between align-items-end'}>
                <span className={'text-size-14 line-height-125rel'}>
                    <FormattedMessage id={'driverRegistration.page1.driverCardCountry.label'} />
                </span>
            </label>
            <select
                className={'form-control input-lg width-100pct'}
                value={driverCardCountry}
                onChange={onChange}
                data-cy={'driver-card-country-select'}
            >
                {driverCardCountries
                    .filter((key: DriverCardCountry) => !driverCardCountryGenericValues.includes(key))
                    .map((key: DriverCardCountry) => ({
                        key,
                        translation: intl.formatMessage({ id: `driverRegistration.page1.country.${key}` }),
                    }))
                    .sort((a, b) => {
                        return a.translation.localeCompare(b.translation);
                    })
                    .concat(
                        driverCardCountryGenericValues.map((key: DriverCardCountry) => ({
                            key,
                            translation: intl.formatMessage({ id: `driverRegistration.page1.country.${key}` }),
                        }))
                    )
                    .map((entry: { key: DriverCardCountry; translation: string }) => (
                        <option key={entry.key} value={entry.key}>
                            {entry.translation}
                        </option>
                    ))}
            </select>
        </div>
    );
};
