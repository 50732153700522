export enum ValidationType {
    Vin,
    DriverCard,
    Email,
    Phone,
}

export const validate = ({ type, value }: { type: ValidationType; value: string }): boolean => {
    switch (type) {
        case ValidationType.Vin:
            return /^[1234567890ABCDEFGHJKLMNPRSTUVWXYZ]{1,17}$/.test(value);
        case ValidationType.DriverCard:
            return /^[a-zA-Z0-9 .:,;_<=>@^`|/(){}[\]$%&"?!#*+~'-\\]{1,14}[A-Z0-9]{0,2}$/.test(value);
        case ValidationType.Email:
            return /^[\x21-\x26\x28-\x7E]+@.+\..+$/g.test(value) && value.length < 74;
        case ValidationType.Phone:
            return /^$|^\+[1-9][0-9]{5,14}$/g.test(value);
    }
};
