export const driverCardCountries = [
    'AUSTRIA',
    'ALBANIA',
    'ANDORRA',
    'ARMENIA',
    'AZERBAIJAN',
    'BELGIUM',
    'BULGARIA',
    'BOSNIA_HERZEGOVINA',
    'BELARUS',
    'SWITZERLAND',
    'CYPRUS',
    'CZECH_REPUBLIC',
    'GERMANY',
    'DENMARK',
    'SPAIN',
    'ESTONIA',
    'FRANCE',
    'FINLAND',
    'LIECHTENSTEIN',
    'FAROE_ISLANDS',
    'UNITED_KINGDOM',
    'GEORGIA',
    'GREECE',
    'HUNGARY',
    'CROATIA',
    'ITALY',
    'IRELAND',
    'ICELAND',
    'KAZAKHSTAN',
    'LUXEMBOURG',
    'LITHUANIA',
    'LATVIA',
    'MALTA',
    'MONACO',
    'REPUBLIC_OF_MOLDOVA',
    'MACEDONIA',
    'NORWAY',
    'NETHERLANDS',
    'PORTUGAL',
    'POLAND',
    'ROMANIA',
    'SAN_MARINO',
    'RUSSIAN_FEDERATION',
    'SWEDEN',
    'SLOVAKIA',
    'SLOVENIA',
    'TURKMENISTAN',
    'TURKEY',
    'UKRAINE',
    'VATICAN_CITY',
    'YUGOSLAVIA',
    'EUROPEAN_COMMUNITY',
    'REST_OF_EUROPE',
    'REST_OF_THE_WORLD',
    'UNKNOWN',
] as const;
export type DriverCardCountry = typeof driverCardCountries[number];

export const driverCardCountryGenericValues: Array<DriverCardCountry> = [
    'EUROPEAN_COMMUNITY',
    'REST_OF_EUROPE',
    'REST_OF_THE_WORLD',
    'UNKNOWN',
];

export const getCountryFromLocale = (locale: string): DriverCardCountry => {
    // TODO: add more locales
    switch (locale) {
        case 'en-GB':
            return 'UNITED_KINGDOM';
        case 'bg-BG':
            return 'BULGARIA';
        case 'cs-CZ':
            return 'CZECH_REPUBLIC';
        case 'da-DK':
            return 'DENMARK';
        case 'de-DE':
            return 'GERMANY';
        case 'el-GR':
            return 'GREECE';
        case 'es-ES':
            return 'SPAIN';
        case 'et-EE':
            return 'ESTONIA';
        case 'fi-FI':
            return 'FINLAND';
        case 'fr-FR':
            return 'FRANCE';
        case 'hr-HR':
            return 'CROATIA';
        case 'hu-HU':
            return 'HUNGARY';
        case 'it-IT':
            return 'ITALY';
        case 'lt-LT':
            return 'LITHUANIA';
        case 'lv-LV':
            return 'LATVIA';
        case 'nb-NO':
            return 'NORWAY';
        case 'nl-NL':
            return 'NETHERLANDS';
        case 'pl-PL':
            return 'POLAND';
        case 'pt-PT':
            return 'PORTUGAL';
        case 'ro-RO':
            return 'ROMANIA';
        case 'sk-SK':
            return 'SLOVAKIA';
        case 'sl-SI':
            return 'SLOVENIA';
        case 'sv-SE':
            return 'SWEDEN';
        default:
            return 'GERMANY';
    }
};

export const mapDriverCardCountryToPrefix = (country: DriverCardCountry): string => {
    switch (country) {
        case 'AUSTRIA':
            return 'A  ';
        case 'ALBANIA':
            return 'AL ';
        case 'ANDORRA':
            return 'AND';
        case 'ARMENIA':
            return 'ARM';
        case 'AZERBAIJAN':
            return 'AZ ';
        case 'BELGIUM':
            return 'B  ';
        case 'BULGARIA':
            return 'BG ';
        case 'BOSNIA_HERZEGOVINA':
            return 'BIH';
        case 'BELARUS':
            return 'BY ';
        case 'SWITZERLAND':
            return 'CH ';
        case 'CYPRUS':
            return 'CY ';
        case 'CZECH_REPUBLIC':
            return 'CZ ';
        case 'GERMANY':
            return 'D  ';
        case 'DENMARK':
            return 'DK ';
        case 'SPAIN':
            return 'E  ';
        case 'ESTONIA':
            return 'EST';
        case 'FRANCE':
            return 'F  ';
        case 'FINLAND':
            return 'FIN';
        case 'LIECHTENSTEIN':
            return 'FL ';
        case 'FAROE_ISLANDS':
            return 'FR ';
        case 'UNITED_KINGDOM':
            return 'UK ';
        case 'GEORGIA':
            return 'GE ';
        case 'GREECE':
            return 'GR ';
        case 'HUNGARY':
            return 'H  ';
        case 'CROATIA':
            return 'HR ';
        case 'ITALY':
            return 'I  ';
        case 'IRELAND':
            return 'IRL';
        case 'ICELAND':
            return 'IS ';
        case 'KAZAKHSTAN':
            return 'KZ ';
        case 'LUXEMBOURG':
            return 'L  ';
        case 'LITHUANIA':
            return 'LT ';
        case 'LATVIA':
            return 'LV ';
        case 'MALTA':
            return 'M  ';
        case 'MONACO':
            return 'MC ';
        case 'REPUBLIC_OF_MOLDOVA':
            return 'MD ';
        case 'MACEDONIA':
            return 'MK ';
        case 'NORWAY':
            return 'N  ';
        case 'NETHERLANDS':
            return 'NL ';
        case 'PORTUGAL':
            return 'P  ';
        case 'POLAND':
            return 'PL ';
        case 'ROMANIA':
            return 'RO ';
        case 'SAN_MARINO':
            return 'RSM';
        case 'RUSSIAN_FEDERATION':
            return 'RUS';
        case 'SWEDEN':
            return 'S  ';
        case 'SLOVAKIA':
            return 'SK ';
        case 'SLOVENIA':
            return 'SLO';
        case 'TURKMENISTAN':
            return 'TM ';
        case 'TURKEY':
            return 'TR ';
        case 'UKRAINE':
            return 'UA ';
        case 'VATICAN_CITY':
            return 'V  ';
        case 'YUGOSLAVIA':
            return 'YU ';
        case 'UNKNOWN':
            return 'UNK';
        case 'EUROPEAN_COMMUNITY':
            return 'EC ';
        case 'REST_OF_EUROPE':
            return 'EUR';
        case 'REST_OF_THE_WORLD':
            return 'WLD';
        default:
            console.error('Could not map country to prefix');
            return '';
    }
};
