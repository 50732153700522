import getOr from 'lodash/fp/getOr';
import { reportErrorToSentry } from '../setup/sentry';
import { Exception } from '@sentry/browser';

const MODULE_NAME = `user-self-registration-web`;

const sendError = (exception: Exception) => {
    reportErrorToSentry(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};

const normalizeDynamicImport = (imported: any) => getOr(imported, 'default', imported);

export const importDisplayMessages = (locale: string) =>
    import(`../../features/translations/${locale}.json`).then(normalizeDynamicImport).catch((error) => {
        sendError(error);
        return error;
    });
