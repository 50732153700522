import { FormattedMessage, useIntl } from 'react-intl';
import { PreferredLogin } from '../types';
import { getPhonePrefixFromDriverCardCountry } from '../utils/phonePrefix';
import { DriverCardCountry } from '../utils/driverCardCountry';

interface Props {
    value: string;
    inputType: 'phone' | 'email';
    inputErrors: any;
    preferredLogin: PreferredLogin;
    handleRadioSelection: (arg: any) => void;
    handleInput: (arg: any) => void;
    isPending: boolean;
    maxLength?: number;
    driverCardCountry?: DriverCardCountry;
}

export const SecondScreenInput = ({
    value,
    inputErrors,
    preferredLogin,
    handleRadioSelection,
    handleInput,
    inputType,
    maxLength,
    isPending,
    driverCardCountry,
}: Props) => {
    const intl = useIntl();

    let inputError: boolean;

    switch (inputType) {
        case 'phone':
            inputError = inputErrors.phone;
            break;
        case 'email':
            inputError = inputErrors.email;
            break;
    }

    const phonePrefix = driverCardCountry ? getPhonePrefixFromDriverCardCountry(driverCardCountry) : '';

    return (
        <div className={'form-group width-100pct' + (inputError ? ' has-error has-feedback' : '')}>
            <label className={'control-label padding-left-25 display-flex justify-content-between align-items-end'}>
                <span className={'text-size-14 line-height-125rel'}>
                    <FormattedMessage id={`driverRegistration.page2.${inputType}.label`} />
                </span>
                {inputType === 'phone' && (
                    <span className={'padding-bottom-1 text-italic white-space-nowrap margin-left-10'}>
                        <span className={'margin-right-3'}>
                            <FormattedMessage id={'driverRegistration.page2.phone.label.example'} />
                        </span>
                        <span>{phonePrefix ? phonePrefix : '+49'}</span>
                        <span>{'xxxxxxx'}</span>
                    </span>
                )}
            </label>
            <div className={'display-flex'}>
                <div className={'margin-right-5 display-flex align-items-center'}>
                    <label className={'radio'}>
                        <input
                            type={'radio'}
                            name={'loginChoice'}
                            value={inputType}
                            checked={preferredLogin === inputType}
                            onChange={handleRadioSelection}
                            disabled={isPending}
                        />
                        <span className={'radio-text'} data-cy={`radio-button-${inputType}`} />
                    </label>
                </div>
                <div className={'flex-1-1'}>
                    <div className={'position-relative'}>
                        <input
                            type={'text'}
                            className={'form-control input-lg'}
                            placeholder={intl.formatMessage({
                                id: `driverRegistration.page2.${inputType}.placeholder`,
                            })}
                            onChange={handleInput}
                            value={value}
                            data-cy={`${inputType}-input`}
                            id={`${inputType}-input`}
                            spellCheck={false}
                            maxLength={maxLength}
                            onClick={handleRadioSelection}
                            readOnly={preferredLogin !== inputType || isPending}
                        />
                        {inputError && <span className={'form-control-feedback rioglyph rioglyph-error-sign'} />}
                    </div>
                    {inputError && (
                        <span className={'help-block'}>
                            <FormattedMessage id={`driverRegistration.page2.${inputType}.validationError`} />
                            {inputType === 'phone' && (
                                <div>
                                    <FormattedMessage
                                        id={`driverRegistration.page2.${inputType}.validationError.explanation`}
                                        values={{ initialCharacter: '+' }}
                                    />
                                    <br />
                                    <FormattedMessage
                                        id={`driverRegistration.page2.${inputType}.validationError.example`}
                                        values={{ example: `${phonePrefix ? phonePrefix : '+49'}17199999999` }}
                                    />
                                </div>
                            )}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
